<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
            <el-input v-model="search.cusName" size="medium" placeholder="客户姓名" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.scheduleState" size="medium" placeholder="档期状态" clearable>
            <el-option
                v-for="item in scheduleStateArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.clothesSize" placeholder="婚纱尺寸" clearable size="medium">
            <el-option
                v-for="item in clothesSizeArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.clothesNo" placeholder="婚纱编号" clearable size="medium"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="3">
          <el-select v-model="search.styleType" placeholder="款式类型" clearable size="medium">
            <el-option
                v-for="item in styleTypeArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.styleName" placeholder="款式名称" clearable size="medium"></el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              style="width: 100%"
              v-model="dateAmongArray"
              type="daterange"
              range-separator="至"
              start-placeholder="使用日期"
              value-format="yyyy-MM-dd"
              end-placeholder="使用日期"
              size="medium">
          </el-date-picker>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="queryScheduleList" size="medium">搜索</el-button>
          <el-button type="warning" @click="reset" size="medium">重置</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
        border
        :data="scheduleArray"
        :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
        max-height="635"
        style="width: 99%">
        <el-table-column label="客户名" prop="customerName" align="center"></el-table-column>
        <el-table-column label="婚纱编号" align="center">
          <template slot-scope="scope">
            {{scope.row.styleType}}-{{scope.row.styleName}}-{{scope.row.clothesSize}}-{{scope.row.clothesNo}}
          </template>
        </el-table-column>
        <el-table-column label="使用日期" prop="scheduleDate" align="center"></el-table-column>
        <el-table-column label="是否撞档" align="center">
          <template slot-scope="scope">
            <span :style="{color: scope.row.scheduleState === '撞挡' ? '#f56c6c' : '#409eff'}">
                      {{scope.row.scheduleState}}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "schedule-list",
  created() {
    this.pageInit()
  },
  data() {
    return {
      search: {
        cusName: "",
        dateAmong: "",
        scheduleState: "",
        styleType: "",
        styleName: "",
        clothesSize: "",
        clothesNo: "",
      },
      scheduleStateArray: [
        {name: "撞档", value: "0"},
        {name: "已确认", value: "1"},
      ],
      clothesSizeArray: [
        {value: "XS"},{value: "S"},
        {value: "M"},{value: "L"},
        {value: "XL"},{value: "XXL"},
        {value: "XXXL"},
      ],
      dateAmongArray: [],
      styleTypeArray: [],
      scheduleArray: [],
      total: 0,
      page: 1,
      limit: 100,
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryStyleTypeIds().then(response => {
        this.styleTypeArray = JSON.parse(response.data.data)
      })
      this.queryScheduleList()
    },
    queryScheduleList() {
      if (this.dateAmongArray != null && this.dateAmongArray.length > 0) {
        this.search.dateAmong = this.dateAmongArray[0]+ " - " +this.dateAmongArray[1]
      }
      this.$axios({
        method: "get",
        url: "/schedule/queryScheduleList",
        params: {
          ...this.search,
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
        },
      }).then(response => {
        this.scheduleArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryScheduleList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryScheduleList()
    },
    reset() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
      this.dateAmongArray = []
    }
  }
}
</script>

<style scoped>

</style>